@import "variables";

/* ScrollBar */
::-webkit-scrollbar {
  width: 0.8vw;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: $yellow;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($yellow, 15%);
}

@font-face {
  font-family: "cera-pro";
  src: url("CeraPro-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "cera-pro";
  src: url("CeraPro-Bold.ttf") format("opentype");
  font-weight: bold;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'cera-pro', sans-serif;
  font-weight: normal;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: 4vw;
  color: white;
}

h2 {
  font-size: 3vw;
}

h3 {
  font-size: 1vw;
}

p {
  font-size: 1.2vw;
}

button {
  font-size: 1.2vw;
  padding: 1vw 3vw;
  border: none;
  border-radius: 6vw;
  cursor: pointer;
  color: white;
}

.yellow-btn {
  margin-top: 1.5vw;
  background: $yellow;

  &:hover {
    background: darken($yellow, 15%);
  }
}

@media (max-width: 1600px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 50px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 20px;
  }

  button {
    font-size: 20px;
    padding: 10px 40px;
    border-radius: 40px;
  }

  .yellow-btn {
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  h2 {
    font-size: 10vw;
  }
}
