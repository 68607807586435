.footer {
  text-align: center;
  background: var(--black);

  div {
    padding: 1%;

    h3 {
      font-size: 1.2vw;
      margin: 1vw 0;
    }

    a, h4 {
      color: white;
      text-decoration: none;
      font-size: 0.8vw;
    }
  }
}

@media(max-width: 1600px) {
  .footer {
    div {
      padding: 10px 2%;

      h3 {
        font-size: 20px;
        margin: 10px 0;
      }

      a, h4 {
        font-size: 13px;
      }
    }
  }
}
